import React from 'react';
import Roadmap from '../components/Roadmap';
import '../App.css';

function MasterRoadmap() {
  return (
    <div className="master-roadmap">
        <h2>Master Roadmap</h2>
        <Roadmap master={true}/>       
    </div>
  );
}

export default MasterRoadmap;
