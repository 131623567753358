import './index.css';
import './App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import About from './pages/About';
import MasterRoadmap from './pages/MasterRoadmap';
import Projects from './pages/Projects';
import ProjectIndividual from './pages/ProjectIndividual';
// import TopNav from './components/TopNav';
import Footer from './components/Footer';

function App() {
  return (
      <Router>
          {/* <TopNav/> */}
          <div className="main">
              <Routes>
                  <Route path="/" element={<Landing/>} />
                  <Route path="/projects" element={<Projects/>} />
                  <Route path="/about" element={<About/>} />
                  {/*Placeholder for testing the Page*/}
                  <Route path="/project-individual" element={<ProjectIndividual/>} />
                  <Route path="/roadmap" element={<MasterRoadmap/>} />
              </Routes>
          </div>
          <Footer/>
      </Router>
  );
}

export default App;
