import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Image, Modal, Badge } from 'react-bootstrap';

function RoadmapCard(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const makeLabel = (cardType) => {
        let variant;
        switch(cardType) {
            case "visual":
                variant = "primary";
                break;
            case "bug fix":
                variant = "danger";
                break;
            case "performance":
                variant = "success";
                break;
            case "update":
                variant = "info";
                break;
            default:
                variant = "dark";
                break;
        }
        return variant;
    }
    
    return (
        <div className={`roadmap-card-${props.project}-${props.title}`}>
            <Card onClick={handleShow} border={"dark"} style={{ width: '15rem' }} className="bg-secondary text-white">
                <Card.Header>
                    <Card.Title>{props.project}</Card.Title>                    
                </Card.Header>
                {props.image ? <Card.Img src={props.image}/> : <></>}
                <Card.Body>
                    <Card.Text>{props.title}</Card.Text>
                    <Badge bg={makeLabel(props.type)}>{props.type}</Badge>
                </Card.Body>
                <Card.Footer>
                    <Card.Text>{props.date}</Card.Text>
                </Card.Footer>
            </Card>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.image ? <Image src={props.image}/> : <></>}
                    <p>
                        this is where the description is supposed to go
                    </p>
                    <Badge bg={makeLabel(props.type)}>{props.type}</Badge>
                </Modal.Body>
                <Modal.Footer>
                    <p>{props.date}</p>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

RoadmapCard.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date),
    image: PropTypes.string,
}

export default RoadmapCard;