import React, { useState, useEffect } from 'react';
import Project from '../components/Project';
import { Row, Col } from 'react-bootstrap';
import '../App.css';

function Projects() {
  const [ projects, setProjects ] = useState([]);

  useEffect(() => {
    //development server
    // fetch('http://localhost:5000/api/projects', {
    //   method: "GET",
    // })
    //   .then((response) => response.json())
    //   .then((data) => {console.log(data); setProjects(data);});
    
    //production server
    fetch('https://joshua-rico-personal-website-api.vercel.app/api/projects', {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {console.log(data); setProjects(data);});

  }, [])
  return (
    <div className="projects">
        <h2>Check out my Projects!</h2>
        <Row>
          {projects && projects.map((project, i) => (
            <Col>
              <Project name={project.name} image={project.image} description={project.description} link={project.link}/>
            </Col>
          ))}
        </Row>
    </div>
  );
}

export default Projects;
