import React from 'react';
import { Container, Image, Row, Col} from 'react-bootstrap';
import HeadshotPhoto from '../images/headshot-3.JPG';
import '../App.css';

function About() {
  return (
    <div className="about">
      <Container>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <Image width={400} height={447} thumbnail={true} src={HeadshotPhoto}/>
          </Col>
          <Col text-align="left">
            <h2>Hello, my name is Joshua Rico.</h2>
            <p>Located in O'ahu, Hawai'i, I aim to gain professional experience in the world of Computer Science.</p>
            <p>I was first introduced to Computer Science through a mentor of my high school robotics team. Although his teachings were in the form of a crash course,
              it was an eye-opener. It felt as if though I found the answer I was always looking for. As of Spring of 2024, I received my BS in Computer Science at
              the University of Hawaii at Manoa (UH Manoa).
            </p>
            <p>While at UH Manoa, I have contributed to academic and student-led projects, with the most notable being the Ground Station User Interface
              of Team Robotic Space Exploration (Team RoSE).
            </p>
            <p>Please feel free to connect with me for any inquiries or opportunities!</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;
