import React from 'react';
import RoadmapCard from './RoadmapCard';
import '../App.css';
import { Container, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';



function Roadmap(props) {

  return (
    <div className={`scrollable-roadmap-${props.project}`}>
      <Container fluid>
        <Row xs={2} md={4} lg={6}>
          {props.master ? 
            <Col>
              <RoadmapCard title={"This roadmap contains milestones and features from all projects."} type={"info"} project={"this.website"} date={"9/23/2024"}/>
              <RoadmapCard title={"Click on the cards to find more details!"} type={"info"} project={"this.website"} date={"9/23/2024"}/>
            </Col> 
          : <></>
          }
          <Col>
            <RoadmapCard title={"Some sort of update"} type={"performance"} image={"https://via.placeholder.com/100"} project={"Some Project Title"} date={"9/23/2024"}/>
            <RoadmapCard title={"Some sort of update"} type={"visual"} project={"Some Project Title"} date={"9/23/2024"}/>
          </Col>
          <Col>
            <RoadmapCard title={"Some sort of update"} type={"visual"} project={"Some Project Title"} date={"9/23/2024"}/>
            <RoadmapCard title={"Some sort of update"} type={"update"} project={"Some Project Title"} date={"9/23/2024"}/>
            <RoadmapCard title={"Some sort of update"} type={"bug fix"} project={"Some Project Title"} date={"9/23/2024"}/>
          </Col>
          <Col>
            <RoadmapCard title={"Some sort of update"} type={"roadmap"} project={"Some Project Title"} date={"9/23/2024"}/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

Roadmap.propTypes = {
  master: PropTypes.bool,
  project: PropTypes.string,
}

export default Roadmap;
