import React from 'react';
import '../App.css';

function ProjectIndividual() {
  return (
    <div className="project-individual">
        <h2> This is the Project Individual Page.</h2>
    </div>
  );
}

export default ProjectIndividual;
