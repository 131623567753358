import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Image, Badge, Stack } from 'react-bootstrap';
import { FaExternalLinkAlt } from "react-icons/fa";
import Headshot from '../images/headshot-3.JPG';
import Project from '../components/Project';
import '../App.css';

function Landing() {
    const [ projects, setProjects ] = useState([]);

    useEffect(() => {
        //development server
        // fetch('http://localhost:5000/api/projects', {
        //   method: "GET",
        // })
        //   .then((response) => response.json())
        //   .then((data) => {console.log(data); setProjects(data);});
        
        //production server
        fetch('https://joshua-rico-personal-website-api.vercel.app/api/projects', {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {console.log(data); setProjects(data);});
    
      }, [])


    return (
        <Container>
            <Row className="landing">
                <Col xs={12} md={8}>
                    <Row style={{"margin-top": "20px"}}>
                        <Col >
                            <div className="landing-header">Hi, I'm Josh</div>
                            <div className="landing-about-body">
                                I attained my BS in Computer Science at the University of Hawaii at Manoa.
                                My passions lie in gathering experience in all disciplines.
                            </div>
                        </Col>
                        <Col md={3} className="image-column">
                            <Image height={150} width={150} src={Headshot} roundedCircle/>
                        </Col>
                    </Row>
                    <Row>
                        <div className="landing-header">Education</div>
                        <Col className="image-column" xs={2} md={1}>
                            <Image height="50px" width="50px" src="https://i.pinimg.com/474x/03/fe/a1/03fea1a7d3c613eea016449ea4b209fc.jpg" roundedCircle/>
                        </Col>
                        <Col>
                            <div className="landing-education-header">
                                <h4>
                                    <a href="https://manoa.hawaii.edu/" className="landing-link">
                                        University of Hawaii at Manoa <FaExternalLinkAlt className="link-icon" style={{fontSize: '10px'}}/>
                                    </a>
                                </h4>
                                <h4>
                                    2020-2024
                                </h4>
                            </div>
                            <div className="landing-education-body">
                                Bachelor's Degree in Computer Science
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="landing-header">Skills</div>
                        <Stack direction="horizontal" gap={2}>
                            <Badge pill bg="dark">React</Badge>
                            <Badge pill bg="dark">NodeJS</Badge>
                            <Badge pill bg="dark">Flask</Badge>
                            <Badge pill bg="dark">Java</Badge>
                            <Badge pill bg="dark">C++</Badge>
                            <Badge pill bg="dark">Python</Badge>
                        </Stack>
                    </Row>
                    <Row>
                        <div className="landing-header">Projects</div>
                        {projects && projects.map((project, i) => (
                            <Col>
                                <Project name={project.name} image={project.image} description={project.description} link={project.link}/>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Landing;
