import React from 'react';
import PropTypes from 'prop-types';
import ExampleCarouselImage from '../images/carousel-background.png';
import { Button, Card, Placeholder } from 'react-bootstrap';
import { FaGithub } from "react-icons/fa";


function Project(props) {
    return (
        <div className={`project-${props.name}`}>
            <Card border={"secondary"} style={{ width: '22rem', height: '30rem' }} className="bg-white text-black">
                <Card.Header>
                    {/* <Card.Img height={200} src={ExampleCarouselImage} alt="Card image" /> */}
                    <Placeholder as={Card.Img} height={200} bg="secondary"/>
                </Card.Header>
                <Card.Body>
                    <Card.Title>{props.name}</Card.Title>
                    {/* <Card.Text>{props.link}</Card.Text> */}
                    <Card.Text>{props.description}</Card.Text>
                    <Card.Text>{props.date}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    <Button className='footer-button' size="sm" variant="dark" href={props.link}>
                        <FaGithub fontSize={'20px'}/>
                    </Button>
                </Card.Footer>
            </Card>
        </div>
    );
}

Project.propTypes = {
    name: PropTypes.string.isRequired,
    image: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
}

export default Project;